<template>
  <div class="invoice-item-root-container">
    <div class="invoice-item-left-container">
      <my-avatar
        :name="dataSource.card_name"
        :img_url="dataSource.card_img_url"
        :size="50"
        style="margin-right:30px;"
      ></my-avatar>
      <div>
        <div style="font-size: 15px;font-weight: bold;color: #000000;">{{ dataSource.card_name }}</div>
        <div
          style="font-size: 12px;font-weight: 500;color: #7D7D7D;"
        >{{ dataSource.company_name==""?'--':dataSource.company_name}}的#预付款交易#</div>
      </div>
    </div>
    <div class="invoice-item-right-container">
      <fee :amount="dataSource.total_amount" :primarySize="18" :secondarySize="15"></fee>
      <div>{{ dataSource.create_time_format }}</div>
    </div>
  </div>
</template>

<script>
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
};
</script>

<style scoped>
.invoice-item-root-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  margin: 10px;
  padding-left: 45px;
  padding-right: 80px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0px 0px 1px #dddddd;
}
.invoice-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
}
.invoice-item-left-container {
  display: flex;
  flex-direction: row;
}
.invoice-item-right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>