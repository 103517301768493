<template>
  <Modal
    v-model="isShowModal"
    footer-hide
    :closable="false"
    :styles="{ top: '60px' }"
  >
    <div class="invoice-detail-modal-root-container" v-if="isShowModal">
      <div class="invoice-detail-modal-top-info-container">
        <div>{{ invoiceDetail.create_time }}</div>
        <div>#预付款交易#已完成</div>
      </div>
      <my-avatar
        :name="invoiceDetail.card_name"
        :img_url="invoiceDetail.card_img_url"
        :size="83"
        style="align-self: center; margin-top: 40px; margin-bottom: 10px"
      ></my-avatar>
      <div
        style="
          align-self: center;
          font-size: 15px;
          font-weight: bold;
          color: #000000;
          margin-bottom: 10px;
        "
      >
        {{ invoiceDetail.card_name }}
      </div>
      <div
        style="
          align-self: center;
          font-size: 13px;
          font-weight: 500;
          color: #5f5f5f;
          margin-bottom: 10px;
        "
      >
        {{ invoiceDetail.company_name }}
      </div>
      <fee
        style="align-self: center"
        :amount="invoiceDetail.total_amount"
        :primarySize="18"
        :secondarySize="15"
      />
      <Divider />
      <div class="invoice-detail-modal-row" style="margin-bottom: 10px">
        <div class="invoice-detail-modal-key">交易ID</div>
        <div class="invoice-detail-modal-val">
          {{ invoiceDetail.invoice_id }}
        </div>
      </div>
      <div class="invoice-detail-modal-row" style="margin-bottom: 10px">
        <div class="invoice-detail-modal-key">付款主体</div>
        <div class="invoice-detail-modal-val">
          {{ invoiceDetail.company_name }}
        </div>
      </div>
      <div class="invoice-detail-modal-row" style="margin-bottom: 60px">
        <div class="invoice-detail-modal-key">付款方式</div>
        <div class="invoice-detail-modal-val">{{ pay_method }}</div>
      </div>
      <div class="invoice-detail-modal-row" style="margin-bottom: 10px">
        <div class="invoice-detail-modal-key">付款金额</div>
        <fee
          :amount="invoiceDetail.salary_amount"
          :primarySize="15"
          :secondarySize="12"
        ></fee>
      </div>
      <div class="invoice-detail-modal-row" style="margin-bottom: 10px">
        <div class="invoice-detail-modal-key">费用</div>
        <div class="invoice-detail-modal-val">
          <fee
            style="align-self: center"
            :amount="invoiceDetail.service_amount"
            :primarySize="15"
            :secondarySize="12"
          />
        </div>
      </div>
      <div class="invoice-detail-modal-row" style="margin-bottom: 10px">
        <div class="invoice-detail-modal-key">#游用工卡#到账</div>
        <fee
          fee-type="in"
          :amount="invoiceDetail.total_amount"
          :primarySize="15"
          :secondarySize="12"
        ></fee>
      </div>
      <div class="invoice-detail-modal-get-invoice" @click="getInvoice">
        获取发票
      </div>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
export default {
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      id: 0,
      isShowModal: false,

      invoiceDetail: {
        invoice_id: 0,
        create_time: "",
        total_amount: 0,
        salary_amount: 0,
        service_amount: 0,
      },

      pay_method: "应用外转账",
    };
  },
  methods: {
    ...mapActions({
      invoiceCanApplyAction: "invoiceCanApply",
      invoiceGetDetailAction: "invoiceGetDetail",
    }),
    open(invoice_id) {
      this.invoiceDetail = {
        invoice_id: invoice_id,
        create_time: "",
        card_name: "",
        card_img_url: "",
        company_name: "",
        total_amount: 0,
        salary_amount: 0,
        service_amount: 0,
      };

      this.invoiceGetDetailAction({
        employer_id: localStorage.getItem("employer_id"),
        invoice_id: this.invoiceDetail.invoice_id,
      })
        .then((res) => {
          res.create_time = moment(res.create_time).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.invoiceDetail = res;
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
      this.isShowModal = true;
    },
    getInvoice() {
      this.invoiceCanApplyAction({
        employer_id: localStorage.getItem("employer_id"),
        invoice_id: this.invoiceDetail.invoice_id,
      })
        .then((res) => {
          this.isShowModal = false;
          this.$emit("get-invoice", this.invoiceDetail.invoice_id, res.can);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.invoice-detail-modal-root-container {
  display: flex;
  flex-direction: column;
  padding: 15px 50px;
}
.invoice-detail-modal-top-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #7d7d7d;
}
.invoice-detail-modal-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.invoice-detail-modal-key {
  font-size: 12px;
  font-weight: 500;
  color: #7d7d7d;
}
.invoice-detail-modal-val {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}
.invoice-detail-modal-get-invoice {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  background: #f2f7fe;
  border-radius: 32px;
  font-size: 15px;
  font-weight: 500;
  color: #0866eb;
  width: 260px;
  height: 60px;
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 30px;
}
</style>