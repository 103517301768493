<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div class="invoice-info-modal-root-container" v-if="isShowModal">
      <div style="font-size: 32px; font-weight: bold; color: #000000">
        获取#{{ invoiceForm.invoice_id }}#的发票
      </div>
      <div
        style="
          font-size: 15px;
          font-weight: bold;
          color: #5e5e5e;
          margin-top: 20px;
        "
      >
        开票金额
      </div>
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 10px;
        "
      >
        <fee
          :amount="invoice_amount"
          :primarySize="32"
          :secondarySize="32"
        ></fee>
        <img :src="icons.rightArrow2" style="margin: 0 50px" />
        <div style="display: flex; flex-direction: row; align-items: center">
          <my-avatar
            :name="card_name"
            :img_url="card_logo"
            :size="30"
          ></my-avatar>
          <span
            style="
              margin-left: 10px;
              font-size: 16px;
              font-weight: bold;
              color: #000000;
            "
            >{{ card_name }}</span
          >
        </div>
      </div>
      <div
        style="
          font-size: 15px;
          font-weight: bold;
          color: #000000;
          margin-top: 30px;
        "
      >
        确认或修改开票主体信息
      </div>
      <div class="invoice-info-modal-subject-container">
        <div
          style="display: flex; flex-direction: row; justify-content: center"
        >
          <div
            :class="[
              'invoice-info-modal-type-item',
              invoiceForm.type == 1
                ? 'invoice-info-modal-type-item-selected'
                : '',
            ]"
            style="
              border-top-left-radius: 30px;
              border-bottom-left-radius: 30px;
            "
            @click="invoiceForm.type = 1"
          >
            专票
          </div>
          <div
            :class="[
              'invoice-info-modal-type-item',
              invoiceForm.type == 2
                ? 'invoice-info-modal-type-item-selected'
                : '',
            ]"
            style="
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
            "
            @click="invoiceForm.type = 2"
          >
            普票
          </div>
        </div>
        <p style="margin-top: 40px" class="invoice-info-modal-title">
          发票抬头
        </p>
        <p style="font-size: 13px; color: #1a1a1a; font-weight: 500">
          {{ company_name }}
        </p>
        <Divider />
        <p class="invoice-info-modal-title">纳税人识别号</p>
        <p style="font-size: 13px; color: #1a1a1a; font-weight: 500">
          {{ numberTaxPayer }}
        </p>
        <Divider />
        <p class="invoice-info-modal-title">
          联系地址(同时作为纸质发票邮寄地址)
        </p>
        <Select v-model="invoiceForm.company_address_id" key="addressSelect">
          <Option
            v-for="item in address_list"
            :key="item.address_id"
            :value="item.address_id"
            :label="item.address"
          >
            <span>{{ item.address }}</span>
          </Option>
        </Select>
        <Divider />
        <p class="invoice-info-modal-title">联系人</p>
        <my-select-people
          v-model="invoiceForm.company_contact_id"
          :data-source="people_list"
          key="peopleSelect"
        />
        <Divider />
        <p class="invoice-info-modal-title">银行卡信息</p>
        <my-select-card
          v-model="invoiceForm.company_accuont_id"
          :data-source="account_list"
          key="cardSelect"
        />
        <Divider />
        <p class="invoice-info-modal-title">备注</p>
        <Input v-model="invoiceForm.more" type="textarea" :rows="5" />
      </div>
      <div class="tools-container">
        <Button type="primary" size="large" @click="save">保存</Button>
      </div>
    </div>
    <invoice-confirm-modal
      ref="invoiceConfirmModal"
      @show-success-modal="showSuccessModal"
    />
    <invoice-success-modal
      ref="invoiceSuccessModal"
      @close="close"
      @show-invoice-history="showInvoiceHistory"
    />
  </Modal>
</template>

<script>
import * as icon from "../../../common/icon";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
import InvoiceConfirmModal from "./InvoiceConfirmModal";
import InvoiceSuccessModal from "./InvoiceSuccessModal";
import MySelectCard from "../../../components/Form/MySelectCard";
import MySelectPeople from "../../../components/Form/MySelectPeople";
import { mapActions } from "vuex";
export default {
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
    "my-select-card": MySelectCard,
    "my-select-people": MySelectPeople,
    "invoice-confirm-modal": InvoiceConfirmModal,
    "invoice-success-modal": InvoiceSuccessModal,
  },
  data() {
    return {
      icons: icon,
      isShowModal: false,

      invoiceForm: {
        employer_id: localStorage.getItem("employer_id"),
        invoice_id: 0,
        type: 1,
        company_address_id: 0,
        company_contact_id: 0,
        company_accuont_id: 0,
        more: "",
      },

      name: "",
      phone: "",
      email: "",
      remark: "",
      img_url: "",
      address: "",
      card_name: "",
      card_logo: "",
      contact_id: 0,
      numberTaxPayer: "",
      invoice_amount: 0,
      company_name: "",
      company_img_url: "",
      people_list: [],
      account_list: [],
      address_list: [],
    };
  },
  methods: {
    ...mapActions({
      getCompanyInfoAction: "getCompanyInfo",
      getAddressListAction: "getAddressList",
      getContactListAction: "getContactList",
      getAccountListAction: "getAccountList",
      invoiceGetDetailAction: "invoiceGetDetail",
    }),
    open(id) {
      this.invoiceForm = {
        employer_id: localStorage.getItem("employer_id"),
        invoice_id: id,
        type: 1,
        company_address_id: 0,
        company_contact_id: 0,
        company_accuont_id: 0,
        more: "",
      };
      this.invoiceGetDetailAction({
        employer_id: localStorage.getItem("employer_id"),
        invoice_id: this.invoiceForm.invoice_id,
      })
        .then((res) => {
          this.card_name = res.card_name;
          this.card_logo = res.card_logo;
          this.company_name = res.company_name;
          this.company_img_url = res.company_img_url;
          this.invoice_amount = res.total_amount;
          let company_info = JSON.parse(res.company_info).Result;
          this.numberTaxPayer = company_info ? company_info.CreditCode : "";

          // 获取联系地址
          this.getAddressListAction({
            employer_id: localStorage.getItem("employer_id"),
            company_id: res.company_id,
          })
            .then((company_res) => {
              this.address_list = company_res;
            })
            .catch((error) => {
              console.error(error);
              this.$Message.error(error);
            });

          // 获取联系人
          this.getContactListAction({
            employer_id: localStorage.getItem("employer_id"),
            company_id: res.company_id,
          })
            .then((res) => {
              this.people_list = res;
            })
            .catch((error) => {
              this.$Message.error(error);
            });

          // 获取银行卡
          this.getAccountListAction({
            employer_id: localStorage.getItem("employer_id"),
            company_id: res.company_id,
          })
            .then((res) => {
              this.account_list = res;
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });

      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
      this.$emit("close");
    },
    save() {
      if (this.invoiceForm.company_address_id == 0) {
        this.$Message.error("请选择联系地址");
        return;
      }
      if (this.invoiceForm.company_contact_id == 0) {
        this.$Message.error("请选择联系人");
        return;
      }
      if (this.invoiceForm.company_accuont_id == 0) {
        this.$Message.error("请选择银行卡信息");
        return;
      }
      this.$refs.invoiceConfirmModal.open({
        name: this.company_name,
        img_url: this.company_img_url,
        idnumber: this.invoiceForm.invoice_id,
        amount: this.invoice_amount,
        company_name: this.company_name,
        formData: this.invoiceForm,
      });
    },
    showSuccessModal() {
      this.$refs.invoiceSuccessModal.open({
        amount: this.invoice_amount,
      });
    },
    showInvoiceHistory() {
      this.$emit("show-invoice-history");
    },
  },
};
</script>

<style scoped>
.invoice-info-modal-root-container {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-left: 280px;
  padding-bottom: 70px;
  background-color: #f7f7f7;
}
.invoice-info-modal-type-item {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #f9eed2;
  background-color: #f7f7f7;
  color: #000000;
}
.invoice-info-modal-type-item-selected {
  background-color: #585449;
  color: #ffffff;
}
.invoice-info-modal-subject-container {
  width: 557px;
  box-shadow: 0px 2px 4px #dddddd;
  padding: 10px 50px 30px 50px;
  border-radius: 25px;
  margin-top: 30px;
}
.invoice-info-modal-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 60%;
}
.invoice-info-modal-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fcf8ea;
  border-radius: 8px;
  width: 300px;
  height: 75px;
  padding: 20px;
}
.invoice-info-modal-title {
  margin-bottom: 15px;
  color: #646464;
  font-weight: 500px;
  font-size: 12px;
}
</style>