<template>
  <Modal v-model="isShowModal" footer-hide>
    <div class="invoice-success-modal-root-container">
      <img
        :src="icons.passSolid"
        style="width: 100px; height: 100px; margin-bottom: 40px"
      />
      <fee :amount="this.amount" :primarySize="32" :secondarySize="32" />
      <div
        style="
          font-size: 21px;
          color: #000000;
          font-weight: 500;
          margin-top: 20px;
        "
      >
        开票申请成功
      </div>
      <div
        style="
          font-size: 13px;
          color: #6d6d6d;
          font-weight: 500;
          width: 62%;
          text-align: center;
          margin: 50px 0;
        "
      >
        您可以在「获取发票」-「开票历史」中关注和追踪发票处理进度
      </div>
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 60%;
        "
      >
        <Button
          size="large"
          style="width: 100px"
          type="primary"
          shape="circle"
          @click="showInoviceHistory"
          >去查看</Button
        >
        <Button size="large" style="width: 100px" shape="circle" @click="close"
          >关闭</Button
        >
      </div>
    </div>
  </Modal>
</template>

<script>
import Fee from "../../../components/Fee";
import * as icon from "../../../common/icon";
export default {
  components: {
    fee: Fee,
  },
  data() {
    return {
      icons: icon,
      isShowModal: false,

      amount: 0,
    };
  },
  methods: {
    open(dataSource) {
      this.amount = dataSource.amount;
      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
      this.$emit("close");
    },
    showInoviceHistory() {
      this.isShowModal = false;
      this.$emit("close");
      this.$emit("show-invoice-history");
    },
  },
};
</script>

<style scoped>
.invoice-success-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 50px;
}
</style>