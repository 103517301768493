<template>
  <Modal v-model="isShowModal" footer-hide :closable="false">
    <div class="invoice-history-detail-modal-root-container" v-if="isShowModal">
      <div class="invoice-history-detail-modal-top-info-container">
        <div>{{ invoiceDetail.apply_time }}</div>
        <div>#预付款交易#已完成</div>
      </div>
      <my-avatar
        :name="invoiceDetail.card_name"
        :img_url="invoiceDetail.card_logo"
        :size="83"
        style="align-self: center; margin-top: 40px; margin-bottom: 10px"
      ></my-avatar>
      <div
        style="
          align-self: center;
          font-size: 15px;
          font-weight: bold;
          color: #000000;
          margin-bottom: 10px;
        "
      >
        {{ invoiceDetail.card_name }}
      </div>
      <div
        style="
          align-self: center;
          font-size: 13px;
          font-weight: 500;
          color: #5f5f5f;
          margin-bottom: 10px;
        "
      >
        {{ invoiceDetail.company_name }}
      </div>
      <fee
        style="align-self: center"
        :amount="invoiceDetail.total_amount"
        :primarySize="18"
        :secondarySize="15"
      ></fee>
      <Divider />
      <div class="invoice-history-detail-modal-row" style="margin-bottom: 10px">
        <div class="invoice-history-detail-modal-key">交易ID</div>
        <div class="invoice-history-detail-modal-val">
          {{ invoiceDetail.invoice_id }}
        </div>
      </div>
      <div class="invoice-history-detail-modal-row" style="margin-bottom: 10px">
        <div class="invoice-history-detail-modal-key">付款主体</div>
        <div class="invoice-history-detail-modal-val">
          {{ invoiceDetail.company_name }}
        </div>
      </div>
      <div class="invoice-history-detail-modal-row" style="margin-bottom: 60px">
        <div class="invoice-history-detail-modal-key">付款方式</div>
        <div class="invoice-history-detail-modal-val">
          {{ invoiceDetail.pay_method }}
        </div>
      </div>
      <div class="invoice-history-detail-modal-row" style="margin-bottom: 10px">
        <div class="invoice-history-detail-modal-key">付款金额</div>
        <fee
          :amount="invoiceDetail.salary_amount"
          :primarySize="15"
          :secondarySize="12"
        ></fee>
      </div>
      <div class="invoice-history-detail-modal-row" style="margin-bottom: 10px">
        <div class="invoice-history-detail-modal-key">费用</div>
        <div class="invoice-history-detail-modal-val">
          <fee
            :amount="invoiceDetail.service_amount"
            :primarySize="15"
            :secondarySize="12"
          ></fee>
        </div>
      </div>
      <div class="invoice-history-detail-modal-row" style="margin-bottom: 10px">
        <div class="invoice-history-detail-modal-key">#游用工卡#到账</div>
        <fee
          fee-type="in"
          :amount="totalAmount"
          :primarySize="15"
          :secondarySize="12"
        ></fee>
      </div>
      <div class="invoice-history-detail-state-container">
        <span v-if="invoiceDetail.state == 2" style="color: #61a851"
          >开票中</span
        >
        <span
          v-else-if="invoiceDetail.state == 3 || invoiceDetail.state == 4"
          style="cursor: pointer; color: #2e84d4"
          @click="getInvoice"
          >下载发票</span
        >
      </div>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
export default {
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      isShowModal: false,

      invoiceDetail: {
        invoice_id: 0,
        card_name: "",
        card_logo: "",
        company_name: "",
        salary_amount: 0,
        service_amount: 0,
        total_amount: 0,
        state: 0,
        apply_time: "",
        pay_method: "应用外转账",
      },
    };
  },
  methods: {
    ...mapActions({
      invoiceGetDetailAction: "invoiceGetDetail",
      invoiceGetInvoiceAction: "invoiceGetInvoice",
    }),
    open(invoice_id) {
      this.invoiceDetail = {
        invoice_id: invoice_id,
        card_name: "",
        card_logo: "",
        company_name: "",
        salary_amount: 0,
        service_amount: 0,
        total_amount: 0,
        state: 0,
        apply_time: "",
        pay_method: "应用外转账",
      };
      this.invoiceGetDetailAction({
        employer_id: localStorage.getItem("employer_id"),
        invoice_id: this.invoiceDetail.invoice_id,
      })
        .then((res) => {
          this.invoiceDetail.card_name = res.card_name;
          this.invoiceDetail.card_logo = res.card_logo;
          this.invoiceDetail.company_name = res.company_name;
          this.invoiceDetail.salary_amount = res.salary_amount;
          this.invoiceDetail.service_amount = res.service_amount;
          this.invoiceDetail.total_amount = res.total_amount;
          this.invoiceDetail.apply_time = moment(res.apply_time).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.invoiceDetail.state = res.state;
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
      this.isShowModal = true;
    },
    getInvoice() {
      this.invoiceGetInvoiceAction({
        employer_id: localStorage.getItem("employer_id"),
        invoice_id: this.invoiceDetail.invoice_id,
      })
        .then((res) => {
          window.open(res);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
  computed: {
    totalAmount() {
      return (
        Number(this.invoiceDetail.salary_amount) +
        Number(this.invoiceDetail.service_amount)
      );
    },
  },
};
</script>

<style scoped>
.invoice-history-detail-modal-root-container {
  display: flex;
  flex-direction: column;
  padding: 15px 50px;
}
.invoice-history-detail-modal-top-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #7d7d7d;
}
.invoice-history-detail-modal-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.invoice-history-detail-modal-key {
  font-size: 12px;
  font-weight: 500;
  color: #7d7d7d;
}
.invoice-history-detail-modal-val {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}
.invoice-history-detail-state-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  margin: 100px 0;
}
</style>