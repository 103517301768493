<template>
  <Modal v-model="isShowModal" footer-hide :closable="false">
    <div class="invoice-confirm-modal-root-container" v-if="isShowModal">
      <div
        style="
          font-size: 23px;
          font-weight: bold;
          color: #000000;
          margin-bottom: 30px;
        "
      >
        获取#{{ dataSource.idnumber }}#的发票
      </div>
      <fee
        :amount="dataSource.amount"
        :primarySize="32"
        :secondarySize="32"
      ></fee>
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 40px;
          margin-bottom: 50px;
        "
      >
        <my-avatar
          :name="dataSource.name"
          :img_url="dataSource.img_url"
          :size="32"
        ></my-avatar>
        <div
          style="
            font-size: 16px;
            font-weight: bold;
            color: #000000;
            margin-left: 15px;
          "
        >
          {{ dataSource.company_name }}
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 50%;
        "
      >
        <div
          style="
            color: #131313;
            font-weight: bold;
            font-size: 15px;
            cursor: pointer;
          "
          @click="close"
        >
          再检查看看
        </div>
        <Button size="large" type="primary" @click="showSuccessModal"
          >确认无误</Button
        >
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
export default {
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      // isShow: false,
      isShowModal: false,

      dataSource: {},
    };
  },
  methods: {
    ...mapActions({
      invoiceApplyAction: "invoiceApply",
    }),
    open(dataSource) {
      this.dataSource = dataSource;
      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
    },
    showSuccessModal() {
      this.invoiceApplyAction(this.dataSource.formData)
        .then((res) => {
          this.isShowModal = false;
          this.$emit("show-success-modal");
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.invoice-confirm-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 50px;
}
</style>