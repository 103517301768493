<template>
  <div class="invoice-root-container">
    <div class="invoice-head-container">
      <div class="page-title">全部未获取发票的交易</div>
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
        "
        @click="showInvoiceHistoryModal"
      >
        <img :src="icons.invoiceHistory" style="margin-right: 5px" />
        <div style="font-size: 15px; color: #3385a6">开票历史</div>
      </div>
    </div>
    <slide-tabbar
      style="margin-top: 20px"
      :tabs="tabs"
      @on-change="onBarChange"
      show-bar
    ></slide-tabbar>
    <div v-if="currentIndex == 0" class="invoice-pay-container">
      <div v-for="(list, index) in invoiceListFormat" :key="index">
        <div
          style="
            font-size: 13px;
            font-weight: 500;
            color: #868686;
            margin-top: 25px;
            margin-bottom: 15px;
          "
        >
          {{ index }}
        </div>
        <div class="invoice-item-container">
          <invoice-item
            v-for="item in list"
            :key="item.invoice_id"
            :data-source="item"
            @click.native="showInvoiceDetailModal(item.invoice_id)"
          ></invoice-item>
        </div>
      </div>
    </div>
    <div v-else class="invoice-expend-container"></div>

    <invoice-info-modal
      ref="invoiceInfoModal"
      @close="refresh"
      @show-invoice-history="showInvoiceHistoryModal"
    />
    <invoice-error-modal ref="invoiceErrorModal" />
    <invoice-detail-modal ref="invoiceDetailModal" @get-invoice="getInvoice" />
    <invoice-history-modal ref="invoiceHistoryModal" />
  </div>
</template>

<script>
import * as _ from "lodash";
import { mapActions } from "vuex";
import moment from "moment";
import * as icon from "../../common/icon";
import InvoiceItem from "./Item/InvoiceItem";
import SlideTabbar from "../../components/SlideTabbar";
import InvoiceInfoModal from "./Modal/InvoiceInfoModal";
import InvoiceErrorModal from "./Modal/InvoiceErrorModal";
import InvoiceDetailModal from "./Modal/InvoiceDetailModal";
import InvoiceHistoryModal from "./Modal/InvoiceHistoryModal";
export default {
  components: {
    "invoice-item": InvoiceItem,
    "slide-tabbar": SlideTabbar,
    "invoice-info-modal": InvoiceInfoModal,
    "invoice-error-modal": InvoiceErrorModal,
    "invoice-detail-modal": InvoiceDetailModal,
    "invoice-history-modal": InvoiceHistoryModal,
  },
  data() {
    return {
      icons: icon,
      tabs: [
        {
          name: "预付款交易",
        },
        // {
        //   name: "用工支出",
        // },
      ],
      invoiceList: [],
      currentIndex: 0,
    };
  },
  computed: {
    invoiceListFormat: function () {
      this.invoiceList.sort(function (a, b) {
        return moment(b.create_time) - moment(a.create_time);
      });
      return _.groupBy(this.invoiceList, "create_time_format_month");
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions({
      invoiceGetListAction: "invoiceGetList",
    }),
    onBarChange(index) {
      this.currentIndex = index;
    },
    refresh() {
      this.invoiceGetListAction({
        employer_id: localStorage.getItem("employer_id"),
        type: 1,
      })
        .then((res) => {
          for (let item of res) {
            item.apply_time = moment(item.apply_time).format(
              "YYYY/MM/DD HH:mm"
            );
            item.create_time_format = moment(item.create_time).format(
              "YYYY/MM/DD HH:mm"
            );
            item.create_time_format_month = moment(item.create_time).format(
              "MM月 YYYY"
            );

            item.state_format = "";
            switch (item.state) {
              case 1:
                item.state_format = "待开";
                break;
              case 2:
                item.state_format = "已申请开票";
                break;
              case 3:
                item.state_format = "已开";
                break;
              case 4:
                item.state_format = "已邮寄";
                break;
            }
          }
          this.invoiceList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    showInvoiceDetailModal(id) {
      this.$refs.invoiceDetailModal.open(id);
    },
    showInvoiceHistoryModal(id) {
      this.$refs.invoiceHistoryModal.open(id);
    },
    getInvoice(id, can) {
      if (can) {
        this.$refs.invoiceInfoModal.open(id);
      } else {
        this.$refs.invoiceErrorModal.open(id);
      }
    },
  },
};
</script>

<style scoped>
.invoice-root-container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 15px;
  margin-right: 140px;
  background-color: #f9f9f9;
}
.invoice-head-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.invoice-item-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px 0;
  border-radius: 10px;
}
</style>