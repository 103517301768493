<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div class="invoice-error-modal-root-container">
      <div class="page-title">获取#{{ id }}#的发票</div>
      <span
        style="
          font-size: 15px;
          font-weight: bold;
          color: #5e5e5e;
          margin-top: 30px;
        "
        >开票金额</span
      >
      <fee :amount="amount" :primarySize="32" :secondarySize="32" />
      <tip-board
        style="margin-top: 50px"
        title="Ooh,您还没有该银行卡的主体信息"
        :subTitle="'卡号：' + num"
        :source="icons.notFound"
      >
        <template slot="button">
          <Button
            type="primary"
            style="width: 100px; margin-top: 40px"
            shape="circle"
            @click="createCompany"
            >立即创建</Button
          >
        </template>
      </tip-board>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import * as icon from "../../../common/icon";
import Fee from "../../../components/Fee";
import TipBoard from "../../../components/TipBoard";
export default {
  components: {
    fee: Fee,
    "tip-board": TipBoard,
  },
  data() {
    return {
      isShowModal: false,

      icons: icon,
      id: 0,
      amount: 0,
      num: "",
    };
  },
  methods: {
    ...mapActions({
      invoiceGetDetailAction: "invoiceGetDetail",
    }),
    open(id) {
      this.id = id;
      this.invoiceGetDetailAction({
        employer_id: localStorage.getItem("employer_id"),
        invoice_id: this.id,
      })
        .then((res) => {
          console.log(res);
          this.amount = res.total_amount;
          this.num = res.from_account_num;
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
      this.isShowModal = true;
    },
    createCompany() {
      this.$router.push({
        name: "employer-home",
      });
    },
  },
};
</script>

<style scoped>
.invoice-error-modal-root-container {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-left: 280px;
  padding-right: 150px;
  padding-bottom: 70px;
  height: 100vh;
  background-color: #f7f7f7;
}
</style>