<template>
  <div>
    <Select size="large" :value="value" @on-change="change">
      <my-avatar
        :name="selectedItem.bank_name"
        :img_url="selectedItem.bank_img_url"
        :size="32"
        slot="prefix"
      />
      <Option
        :value="item.account_id"
        :label="item.num"
        v-for="item in dataSource"
        :key="'account-' + item.account_id"
      >
        <div style="display: flex; flex-direction: row; align-items: center">
          <my-avatar
            :name="item.bank_name"
            :img_url="item.bank_img_url"
            :size="32"
            style="margin-right: 10px"
          />
          <div style="flex: 1; display: flex; flex-direction: column">
            <div>{{ item.sub_name }}</div>
            <div style="display: flex; flex-direction: row">
              <div
                style="display: flex; flex-direction: row; margin-right: 10px"
              >
                <Icon :size="12" type="ios-card" />
                <div style="margin-left: 5px; font-size: 12px; color: #5f5f5f">
                  {{ item.num }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Option>
    </Select>
  </div>
</template>

<script>
import MyAvatar from "../MyAvatar";
export default {
  model: {
    prop: "value",
    event: "on-change",
  },
  components: {
    "my-avatar": MyAvatar,
  },
  props: {
    value: Number,
    dataSource: Array,
  },
  data() {
    return {
      selectedItem: {
        bank_name: "",
        bank_img_url: null,
      },
    };
  },
  methods: {
    change(value) {
      this.selectedItem = this.dataSource.find(
        (item) => item.account_id == value
      );
      this.$emit("on-change", value);
    },
  },
};
</script>

<style scoped>
.my-select-card-title {
  margin-bottom: 15px;
  color: #646464;
  font-weight: 500px;
  font-size: 12px;
}
.my-select-card-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 60%;
}
</style>