<template>
  <div class="invoice-history-item-root-container">
    <div class="invoice-history-item-left-container">
      <div style="display: flex; flex-direction: row">
        <my-avatar
          :name="dataSource.card_name"
          :img_url="dataSource.card_img_url"
          :size="50"
          style="margin-right: 30px"
        />
        <div>
          <div
            style="
              font-size: 15px;
              font-weight: bold;
              color: #000000;
              margin-bottom: 5px;
            "
          >
            {{ dataSource.card_name }}
          </div>
          <div style="font-size: 12px; font-weight: 500; color: #7d7d7d">
            {{ dataSource.company_name }}的#预付款交易#
          </div>
        </div>
      </div>
      <div class="invoice-history-item-fee-container">
        <fee
          :amount="dataSource.total_amount"
          :primarySize="18"
          :secondarySize="15"
        ></fee>
        <div>与{{ dataSource.create_time }}申请开票</div>
      </div>
    </div>
    <div class="invoice-history-item-right-container">
      <span
        v-if="dataSource.state == 2"
        style="font-size: 12px; font-weight: 500; color: #61a851"
        >开票中</span
      >
      <span v-else style="font-size: 12px; font-weight: 500; color: #a85151"
        >已邮寄</span
      >
    </div>
  </div>
</template>

<script>
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
};
</script>

<style scoped>
.invoice-history-item-root-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  margin: 2px 0;
  padding-left: 45px;
  padding-right: 80px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0px 0px 1px #dddddd;
}
.invoice-history-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
}
.invoice-history-item-left-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.invoice-history-item-right-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
}
.invoice-history-item-fee-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>