<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div v-if="isShowModal" class="invoice-history-modal-root-container">
      <div class="invoice-history-modal-head-container">
        <div class="page-title">查看开票历史</div>
        <fee :amount="totalAmount" :primarySize="32" :secondarySize="29" />
      </div>

      <div class="invoice-history-total-container">
        共{{ invoiceList.length }}条，累计开票金额
      </div>

      <div>
        <invoice-history-item
          v-for="item in invoiceList"
          :key="item.invoice_id"
          :data-source="item"
          style="margin-bottom: 20px"
          @click.native="showDetailModal(item)"
        />
      </div>

      <invoice-history-detail-modal ref="invoiceHistoryDetailModal" />
    </div>
  </Modal>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import Fee from "../../../components/Fee.vue";
import InvoiceHistoryItem from "../Item/InvoiceHistoryItem";
import InvoiceHistoryDetailModal from "./InvoiceHistoryDetailModal";
export default {
  components: {
    fee: Fee,
    "invoice-history-item": InvoiceHistoryItem,
    "invoice-history-detail-modal": InvoiceHistoryDetailModal,
  },
  data() {
    return {
      isShowModal: false,

      invoiceList: [],
    };
  },
  computed: {
    totalAmount() {
      let result = 0;
      for (let item of this.invoiceList) {
        result += item.total_amount;
      }
      return result;
    },
  },
  methods: {
    ...mapActions({
      invoiceGetListAction: "invoiceGetList",
    }),
    open(id) {
      this.isShowModal = true;

      this.invoiceGetListAction({
        employer_id: localStorage.getItem("employer_id"),
        type: 2,
      })
        .then((res) => {
          for (let item of res) {
            item.apply_time = moment(item.apply_time).format(
              "YYYY/MM/DD HH:mm"
            );
            item.create_time = moment(item.create_time).format(
              "YYYY/MM/DD HH:mm"
            );

            item.state_format = "";
            switch (item.state) {
              case 1:
                item.state_format = "待开";
                break;
              case 2:
                item.state_format = "已申请开票";
                break;
              case 3:
                item.state_format = "已开";
                break;
              case 4:
                item.state_format = "已邮寄";
                break;
            }
          }
          this.invoiceList = res;
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
    showDetailModal(item) {
      this.$refs.invoiceHistoryDetailModal.open(item.invoice_id);
    },
  },
};
</script>

<style scoped>
.invoice-history-modal-root-container {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-left: 285px;
  padding-right: 150px;
}
.invoice-history-modal-head-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.invoice-history-total-container {
  align-self: flex-end;
  font-size: 12px;
  font-weight: 500;
  color: #7d7d7d;
  margin-top: 10px;
  margin-bottom: 50px;
}
</style>